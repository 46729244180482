import { ReactComponent as CheckboxIcon } from '../../assets/icons/checkbox.svg';
import { ReactComponent as CheckboxTickedIcon } from '../../assets/icons/checkbox-ticked.svg';
import {
  DataGrid,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowParams,
  GridColumnHeaders,
  GridValidRowModel,
  gridClasses,
  useGridApiContext,
  useGridSelector,
  gridRowSelectionStateSelector
} from '@mui/x-data-grid';
import { Box, Checkbox, IconButton, Popover, Stack, Typography, alpha, styled } from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import React from 'react';
import { ButtonCover } from '@ui/button';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import palette from '@utils/palette';
import { useAuth } from '@contexts/AuthContext';

const ODD_OPACITY = 0.2;
const GridComp = styled(DataGrid)(({ theme }) => {
  return {
    [`&.${gridClasses.root}`]: {
      border: 'none',
      zIndex: '0'
    },
    [`&.${gridClasses.root} .MuiDataGrid-withBorderColor`]: {
      border: 'none'
    },
    [`& .${gridClasses.row}.even:first-of-type`]: {
      borderTop: '1px solid #30343624'
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: '#FFFFFF'
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: '#F0F8FF',
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            )
          }
        }
      }
    }
  };
});


const CustomHeader = ({ template, onAction, rows }: any) => React.forwardRef((props: any, ref: any) => {
  const apiRef = useGridApiContext();
  const selectedRows = useGridSelector(apiRef, gridRowSelectionStateSelector);

  const [selectedOption, setSelectedOption] = useState(null as any);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, option: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedOption(option);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const {user} = useAuth();

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {selectedRows.length ? <Stack direction="row" alignItems="center" sx={{ height: '100%', background: 'transparent', position: 'absolute', zIndex: 999 }}>
          <Stack direction="row" sx={{
            ml: 2,
            borderRadius: '.25rem',
            backgroundColor: palette.white,
            boxShadow: '0px 3px 8px 0px rgba(48, 52, 54, 0.08), 1px 1px 6px 0px rgba(0, 0, 0, 0.15)'
          }}>
            <ButtonCover onClick={() => {apiRef.current.setRowSelectionModel([])}} sx={{
              px: 1.5,
              py: 1,
              borderRight: '1px solid #e6ecef',
              background: '#f0f8ff'
            }}>
              <Typography variant='body1' sx={{ color: '#3478b7' }}>{selectedRows?.length} selected <ClearRoundedIcon sx={{fontSize: '1rem', verticalAlign: 'middle'}}/></Typography>
            </ButtonCover>
            <ButtonCover onClick={(e) => handleClick(e, 'assign')} sx={{
              px: 1.5,
              py: 1,
              borderRight: '1px solid #e6ecef'
            }}>
              <PersonRoundedIcon sx={{ height: '1.25rem', width: '1.25rem' }} />
              <Typography variant='body1'>Assign</Typography>
              
            </ButtonCover>
            <ButtonCover onClick={(e) => handleClick(e, 'priority')} sx={{
              px: 1.5,
              py: 1,
              borderRight: '1px solid #e6ecef'
            }}>
              <FlagRoundedIcon sx={{ height: '1.25rem', width: '1.25rem' }} />
              <Typography variant='body1'>Priority</Typography>
            </ButtonCover>
            <ButtonCover onClick={(e) => handleClick(e, 'status')} sx={{
              px: 1.5,
              py: 1,
              borderRight: '1px solid #e6ecef'
            }}>
              <HourglassTopRoundedIcon sx={{ height: '1.25rem', width: '1.25rem' }} />
              <Typography variant='body1'>Status</Typography>
            </ButtonCover>
          </Stack>        </Stack> : ''}
        <GridColumnHeaders {...props} ref={ref} />
      </Box>
      {open && <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {selectedOption === 'assign' && <Box sx={{padding: '16px', display: 'flex', flexDirection: 'column', gap: '16px', marginY: '8px'}}>
          {user?.techUsers.map(tech => <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px', cursor: 'pointer'}} onClick={() => {
            onAction({action: 'assign', selectedRows, value: tech, rows});
            handleClose()
          }}>
            <CheckboxIcon/> <Typography>{tech.name}</Typography>
          </Box>)}
        </Box>}
        {selectedOption === 'priority' && <Box sx={{padding: '16px', display: 'flex', flexDirection: 'column', gap: '16px', marginY: '8px'}}>
          {[
            {id: 'High', name: 'High'}, 
            {id: 'Medium', name: 'Medium'}, 
            {id: 'Low', name: 'Low'}, 
            {id: 'No Priority', name: 'No Priority'}
          ].map(tech => <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px', cursor: 'pointer'}} onClick={() => {
            onAction({action: 'priority', selectedRows, value: tech.id, rows});
            handleClose()
          }}>
            <CheckboxIcon/> <Typography>{tech.name}</Typography>
          </Box>)}
        </Box>}
        {selectedOption === 'status' && <Box sx={{padding: '16px', display: 'flex', flexDirection: 'column', gap: '16px', marginY: '8px'}}>
          {[
            {id: 'Submitted', name: 'Submitted'}, 
            {id: 'Scheduled', name: 'Scheduled'}, 
            {id: 'In Progress', name: 'In Progress'}, 
            {id: 'Completed', name: 'Completed'}, 
            {id: 'On Hold', name: 'On Hold'}
          ].map(tech => <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px', cursor: 'pointer'}} onClick={() => {
            onAction({action: 'status', selectedRows, value: tech.id, rows});
            handleClose()
          }}>
            <CheckboxIcon/> <Typography>{tech.name}</Typography>
          </Box>)}
        </Box>}
      </Popover> }
    </>
  )
})

const checkedIcon = (
  <IconButton>
    <CheckboxTickedIcon />
  </IconButton>
);

const unCheckedIcon = (
  <IconButton>
    <CheckboxIcon />
  </IconButton>
);

interface IGridProps {
  initialState: GridInitialStateCommunity;
  columns: GridColDef[];
  rows: readonly GridValidRowModel[];
  total: number;
  setPaginationModel: (state: any) => any;
  paginationModel: any;
  setSortModel: (state: any) => any;
  sortModel: any[];
  checkboxSelection: boolean;
  setCellModesModel?: (state: any) => any;
  cellModesModel?: any;
  onCellClick?: (params: GridCellParams, event: React.MouseEvent) => void;
  onRowClick?: (params: GridRowParams, event: React.MouseEvent) => void;
  enableRowSelectAction?: boolean;
  rowSelectActionTemplate?: ReactNode;
  onAction?: any;
}

export default function WorkOrderGrid({
  initialState,
  columns,
  rows,
  total,
  setPaginationModel,
  paginationModel,
  setSortModel,
  sortModel,
  checkboxSelection,
  setCellModesModel,
  cellModesModel,
  onCellClick,
  onRowClick,
  enableRowSelectAction,
  rowSelectActionTemplate,
  onAction = () => {}
}: IGridProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const visibility = { ...initialState?.columns?.columnVisibilityModel };
  const [visibilityModel, setVisibilityModel] =
    useState<GridColumnVisibilityModel>(visibility);

  //const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel && setCellModesModel(newModel);
    },
    []
  );

  return (
    <GridComp
      initialState={initialState}
      slots={{
        baseCheckbox: (props) => (
          <Checkbox {...props} checkedIcon={checkedIcon} icon={unCheckedIcon} />
        ),
        columnHeaders: useMemo(() => CustomHeader({ template: rowSelectActionTemplate, onAction, rows }), [rows])
      }}
      sx={{ mb: 4 }}
      autoHeight
      rowHeight={50}
      checkboxSelection={checkboxSelection}
      disableColumnFilter={true}
      disableColumnMenu={true}
      loading={isLoading}
      rowCount={total}
      rows={rows}
      columns={columns}
      paginationMode="server"
      pageSizeOptions={[10, 25, 50, 100]}
      paginationModel={paginationModel}
      hideFooter
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={(newModel) => setSortModel(newModel)}
      columnVisibilityModel={visibilityModel}
      disableRowSelectionOnClick
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={onCellClick}
      onRowClick={onRowClick}
    />
  );
}
