export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  token: string;
  role: string;
  properties: Array<any>;
  users: Array<any>;
  techUsers: Array<any>;
  mrUsers: Array<any>;
  selectedProperty?: any;
  categories?: Array<any>;
  lang?: string;
  aJobs?: any;
  thirdPartyId?: string;
}

export interface IDateFilter {
  from?: string;
  to?: string;
}

export interface IQuerySort {
  column?: string;
  direction?: number;
}

export const enum WORK_TYPE {
  PLUMING = 'Plumbing',
  HAZARD = 'Hazard',
  APPLIANCE = 'Appliances',
  INTERIOR = 'Interior',
  ELECTRIC = 'Electric',
  HVAC = 'HVAC',
  PREVENT = 'Preventative'
}

export const enum PRIORITY_TYPE {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  NO = 'No Priority'
}

export const enum WORK_ORDER_TYPE {
  OPEN = 'Open',
  CLOSE = 'Close'
}
