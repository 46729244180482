import { Box, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { FormInputField, FormSelectField } from "@ui/form";
import PrimaryButton from "@components/buttons/PrimaryButton";
import palette from "@utils/palette";
import { useAuth } from "@contexts/AuthContext";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

interface ITenantIssue {
    control: Control<any>;
    errors: FieldErrors<any>;
    onNext: () => void;
    setValue: any;
    isValid: boolean;
    units: any;
}

export default function TenantIssue({ setValue, control, errors, onNext, isValid, units }: ITenantIssue) {

    const {user} = useAuth();

    const [selectedCategory, setSelectedCategory] = useState(null as any);
    const onCategorySelect = (e:any) => {
        setValue('category', e.target.value);
        setSelectedCategory(e.target.value);
    }

    return <>
        <Stack sx={{ mt: 3, mb: 12, backgroundColor: palette.white, px: 3, py: 20 / 8 }}>
            <Typography variant="body1" sx={{ fontWeight: 500, mb: 3, color: '#5A6A72' }}>Tenant Issue</Typography>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Controller
                        name="area"
                        control={control}
                        rules={{
                            required: "Area is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Area"
                                    {...field}
                                    error={errors?.area}
                                    helperText={errors?.area?.message}
                                >
                                    <MenuItem value={"0"}>Bathroom 1</MenuItem>
                                    <MenuItem value={"1"}>Bathroom 2</MenuItem>
                                    <MenuItem value={"2"}>Bedroom 1</MenuItem>
                                    <MenuItem value={"3"}>Bedroom 2</MenuItem>
                                    <MenuItem value={"4"}>Bedroom 3</MenuItem>
                                    <MenuItem value={"5"}>Kitchen</MenuItem>
                                    <MenuItem value={"6"}>Hallway</MenuItem>
                                    <MenuItem value={"7"}>Living Room</MenuItem>
                                    <MenuItem value={"8"}>Patio</MenuItem>
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="category"
                        control={control}
                        rules={{
                            required: "Category is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Category"
                                    {...field}
                                    error={errors?.category}
                                    helperText={errors?.category?.message}
                                    onChange={onCategorySelect}
                                >
                                    {user?.categories?.map(cat => <MenuItem value={cat.id}>{cat.name}</MenuItem>)}
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="issueType"
                        control={control}
                        rules={{
                            required: "Issue Type is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Issue Type"
                                    {...field}
                                    error={errors?.issueType}
                                    helperText={errors?.issueType?.message}
                                >
                                    {user?.categories?.find(c => c.id === selectedCategory)?.subCategories.map((cat: any) => <MenuItem value={cat.id}>{cat.name}</MenuItem>)}
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="priority"
                        control={control}
                        rules={{
                            required: "Priority is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Priority"
                                    {...field}
                                    error={errors?.priority}
                                    helperText={errors?.priority?.message}
                                >
                                    <MenuItem value={"High"}>High</MenuItem>
                                    <MenuItem value={"Medium"}>Medium</MenuItem>
                                    <MenuItem value={"Low"}>Low</MenuItem>
                                    <MenuItem value={"No Priority"}>No Priority</MenuItem>
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="unitNumber"
                        control={control}
                        rules={{
                            required: "Unit Number is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label={<FormattedMessage id="Unit Number" />}
                                    {...field}
                                    error={errors?.unitNumber}
                                    helperText={errors?.unitNumber?.message}
                                >
                                    {units?.map((unit: any) => <MenuItem value={unit.unitNumber}>{unit.unitNumber}</MenuItem>)}
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="description"
                        control={control}
                        rules={{
                            required: "Description is required.",
                            maxLength: 245,
                        }}
                        render={({ field }) => {
                            return (
                                <FormInputField
                                    multiline={true}
                                    minRows={4}
                                    maxRows={4}
                                    label="Description"
                                    {...field}
                                    placeholder={'Description (max 240 characters)'}
                                    error={errors?.description}
                                    helperText={errors?.description?.message}
                                />
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </Stack>
        <Stack sx={{ backgroundColor: palette.white, px: 3, py: 20 / 8, marginTop: 'auto', position: 'fixed', bottom: 0, width: '100%', maxWidth: '432px' }}>
            <Box sx={{  }}>
                <PrimaryButton isDisabled={!isValid} isFullWidth={true} onClick={onNext} btnText="Continue" />
            </Box>
        </Stack>

    </>
}